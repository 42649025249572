import React from 'react'
import backimg from "../../img/red.jpg";
import "./info.scss"
import { Link } from 'react-router-dom';

const Info = () => {
  return (
    <div
      className="info_home"
      style={{
        backgroundImage: `url(${backimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "contain",
      }}
    >
      <div className="info">
        <div className="info_text">
          <h1>Information </h1>
          <img src={require("../../img/Mechanism CD Game.png")} alt="" />
          <Link to={"/"}>
            <button className="__button">
              <p>Play Game</p>
            </button>
          </Link>
        </div>
        <div className="info_img">
          <img src={require("../../img/gift.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Info
