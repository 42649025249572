import React, { useContext, useEffect, useState } from "react";
import Wheel from "../../components/wheel/Wheel";
import "./btob.scss";
import { AuthContext } from "../../context/AuthContext";
import Com_Login from "../../components/login/Com_Login";
import Com_Register from "../../components/login/Com_Register";
import { IoIosCloseCircleOutline } from "react-icons/io";
import background from "../../img/repart.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import WheelCon from "../../components/wheel/WheelCon";
import g_back from "../../img/center.jpg";
import WhhelChocodate from "../../components/wheel/WhhelChocodate";
import axios from "axios";
import countryList from "./countryList.js";

const ChocodateWheel = () => {
  const { qrCode, promo } = useParams();
  const { user, loading } = useContext(AuthContext);
  const [showBtn, setShowBtn] = useState(false);
 

  const [birthDay, setBirthDay] = useState("");
  const [residence, setResidence] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [winItem, setWinItem] = useState("");

  const promotion_name = "always-a-winner";
  const qr = qrCode;
  const [localLoading, setLocalLoading] = useState(false);
  const [completeRequest, setCompleteRequest] = useState("");



  const [winCount, setWinCount] = useState(0);
  const [updateCount, setUpdateCount] = useState([]);
  const [newUpdateCount, setNewUpdateCount] = useState("");

  // console.log("residence", residence, "nationality", nationality);

  //  console.log(winItem.name);

  // const devApiLink = "http://localhost:4047/api/v1";
  const devApiLink = "https://chocodate-api.notionspromos.com/api/v1";

  const handleShowBtn = () => {
    setShowBtn(!showBtn);
  };

  const closeWindow = () => {
    setWinItem("");
  };

  const navigate = useNavigate();

  const handleWinItemChange = (newWinItem) => {
    setTimeout(() => {
      setWinItem(newWinItem);
    }, 2000);
  };

  const getUpdateWinCount = async () => {
    try {
      const response = await axios.get(`${devApiLink}/getWinCount`);
      const foundItem = response.data.find(
        (item) => item.winCount !== undefined
      ); // Check for defined winCount
      if (foundItem) {
        const filterWinCount = Number(foundItem.winCount);
        setUpdateCount(filterWinCount);
      } else {
        console.log("No valid winCount found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpin = () => {
    if (winItem) {
      setWinCount(1);
    } else {
      console.log("No winning item this time.");
    }
  };

  //  console.log("winCount",winCount);
  //   console.log("updateCount", updateCount);

  const getNewUpdatedWinCount = () => {
    const count = updateCount + winCount;
    setNewUpdateCount(count);
  };

  const updateCountinFB = async () => {
    const count = {
      newUpdateCount,
    };
    if (!winItem) {
      return;
    }
    try {
      await axios.put(`${devApiLink}/winCount`, count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSpin();
    getUpdateWinCount();
   
  }, [updateCount, winItem]);

  useEffect(()=>{
 updateCountinFB();
  },[completeRequest])

  useEffect(() => {
    getNewUpdatedWinCount();
  }, [winCount, winItem]);

  // console.log("newUpdateCount", newUpdateCount);

  const places = [
    { name: "1 Free Family pack", img: require("../../img/1Family.png") },
    { name: "50% Off on next order", img: require("../../img/50%off.png") },
    { name: "2 Movie Ticket", img: require("../../img/cinema.png") },
    { name: "50% Off on next order", img: require("../../img/50%off.png") },
    { name: "GOLD COIN", img: require("../../img/gold.png") },
    { name: "50% Off on next order", img: require("../../img/50%off.png") },
    { name: "Mobile Phone", img: require("../../img/phone.png") },
    { name: "50% Off on next order", img: require("../../img/50%off.png") },
  ];

  const handleSaveWinner = async (e) => {
    e.preventDefault();
    setLocalLoading(true);
    const userData = {
      qr,
      promotion_name,
      winItem: winItem?.name || null,
      userEmail: email,
      userName: name,
      phone,
      birthDay,
      residence,
      nationality,
    };
    try {
      const response = await axios.post(`${devApiLink}/save-winner`, userData);
      setCompleteRequest(response.status === 200);
      if (response.status === 200) {
         window.location.href = "https://www.chocodate.com/products/1";
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <div className="__max_w ">
      <div className="__min_w">
        {winItem && (
          <div className="popup">
            <>
              {localLoading ? (
                <div className="endMsg">
                  <div className="check_wrp">
                    <div className="check_wrp_in">
                      <img src={require("../../img/okay-min.gif")} alt="" />
                    </div>
                  </div>
                  <div className="endMsgwrp">
                    <p style={{ color: "white", lineHeight: "1.6" }}>
                      Just a moment! We're redirecting you to the Chocodate
                      website. Please check your registered email; you’ll
                      receive a message from us within 2 minutes
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="form_wrp">
                    <div className="close">
                      <Link to={"/"}>
                        <IoIosCloseCircleOutline color="white" size={18} />
                      </Link>
                    </div>
                    <div className="win_rwp">
                      <>
                        <img
                          style={{ width: "200px", marginBottom: "20px" }}
                          src={require("../../img/chocodate.webp")}
                          alt=""
                        />
                        <img src={require("../../img/win_flsh.gif")} alt="" />

                        <div>
                          <h2
                            style={{
                              color: "white",
                              marginTop: "20px",
                              fontSize: "20px",
                            }}
                          >
                            Congratulations! 🎉 You've won a
                          </h2>
                        </div>
                        <h1>{winItem.name} </h1>
                      </>
                    </div>
                    {completeRequest ? (
                      <></>
                    ) : (
                      <>
                        <div className="wheel_form">
                          <div className="head">
                            <p>
                              Just fill in your details below, and our team will
                              reach out by email or phone to arrange your prize
                              delivery!
                            </p>
                          </div>
                          <form className="_form" onSubmit={handleSaveWinner}>
                            <div>
                              <label className="__label"> Full Name</label>
                              <input
                                type="text"
                                required
                                placeholder="Full Name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div>
                              <label className="__label"> Email</label>
                              <input
                                type="email"
                                required
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="div_wrp">
                              <div>
                                <label className="__label"> Phone</label>
                                <input
                                  type="tel"
                                  required
                                  placeholder="Phone"
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>
                              <div>
                                <label className="__label"> Residence</label>
                                <select
                                  required
                                  value={residence}
                                  onChange={(e) => setResidence(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  {countryList.map((country) => (
                                    <option
                                      key={country.name}
                                      value={country.name}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="div_wrp">
                              <div>
                                <label className="__label"> Nationality</label>
                                <select
                                  required
                                  value={nationality}
                                  onChange={(e) =>
                                    setNationality(e.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  {countryList.map((country) => (
                                    <option
                                      key={country.name}
                                      value={country.name}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <label className="__label">
                                  {" "}
                                  Date of Birth
                                </label>
                                <input
                                  style={{ width: "100%" }}
                                  required
                                  type="date"
                                  value={birthDay}
                                  onChange={(e) => setBirthDay(e.target.value)}
                                />
                              </div>
                            </div>

                            <button type="submit" disabled={loading}>
                              {loading ? "Submitting in..." : "Submit"}
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          </div>
        )}
      </div>
      <div className="wheel_wrp">
        <div className="wheel_con">
          <WhhelChocodate
            items={places}
            onWinItemChange={handleWinItemChange}
            winCount={newUpdateCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ChocodateWheel;
